import axios from "axios";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import "./Chamber.css";
import { PeopleList } from "./PeopleList";
import { PeopleSearch } from "./PeopleSearch";
import { Urls } from "./Urls";
import Chat from "../chat/Chat";
import styled from "styled-components";
import { FilterMode } from "./Frontpage";
import EmojiButton from "../util/EmojiButton";
import { mobileWidth, StyledInput } from "../../utils/styles";
import Links from "./Links";
import { colors } from "../../utils/colors";
import InfiniteFeed from "./InifiniteFeed";
import ChamberInfiniteFeed from "./ChamberInfiniteFeed";

const Headline = ({
  headline,
  setHeadline,
}: {
  headline: string;
  setHeadline: (value: string) => void;
}) => {
  const [showInput, setShowInput] = useState(false);

  const [input, setInput] = useState(headline);

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setHeadline(input);
    setShowInput(false);
    setInput("");
  };

  if (showInput) {
    return (
      <ChamberHeadline>
        <form onSubmit={onSubmit}>
          <StyledInput
            name="headlineInput"
            autoFocus={true}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <ChamberHeadlineButton type={"submit"}>ok</ChamberHeadlineButton>
          <ChamberHeadlineButton onClick={() => setShowInput(false)}>
            cancel
          </ChamberHeadlineButton>
        </form>
      </ChamberHeadline>
    );
  } else {
    return (
      <ChamberHeadline>
        <HeadlineText onDoubleClick={() => setShowInput(true)}>
          {headline}
        </HeadlineText>
        <ChamberHeadlineButton
          onClick={() => {
            setInput("");
            setShowInput(true);
          }}
        >
          {headline === "" ? "set chamber headline" : "edit"}
        </ChamberHeadlineButton>
      </ChamberHeadline>
    );
  }
};

export const Chamber = () => {
  let { slug } = useParams();

  const navigate = useNavigate();
  const [headliner, setHeadliner] = useState();
  const [subs, setSubs] = useState([]);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.NEW);

  useQuery(
    ["chamber", slug],
    () => fetch(Urls.api.getChamber(slug)).then((res) => res.json()),
    {
      placeholderData: { subscriptions: [] },
      onSuccess: (data) => {
        // Check if subscriptions are empty. This likely means that someone "removed" the last
        // subscription in a chamber.
        if (data.subscriptions.join() === "") {
          navigate(Urls.www.chamberIndex());
        }

        setHeadliner(data.headline);
        setSubs(data.subscriptions);
      },
      onError: () => {
        navigate(Urls.www.chamberIndex());
      },
    }
  );

  const setHeadlineMutation = useMutation(
    (newHeadline: string) =>
      axios.post(Urls.api.postHeadline(slug), { headline: newHeadline }),
    {
      onSuccess: ({ data }) => {
        setHeadliner(data.headline);
      },
    }
  );

  const setHeadline = (newHeadline: string) =>
    setHeadlineMutation.mutate(newHeadline);

  const upforkMutation = useMutation(
    (person: string) => axios.post(Urls.api.upfork(slug), { personid: person }),
    {
      onSuccess: ({ data }) => {
        navigate(Urls.www.getChamber(data.slug));
      },
    }
  );

  const upfork = (personid: string) => upforkMutation.mutate(personid);

  const downforkMutation = useMutation(
    (person: string) =>
      axios.post(Urls.api.downfork(slug), { personid: person }),
    {
      onSuccess: ({ data }) => {
        // If we feel that a new pageload is too disruptive here,
        // we might try to update headline, subs, and push new history
        // without page reload if that is possible.
        navigate(Urls.www.getChamber(data.slug));
      },
    }
  );

  const downfork = (personid: string) => downforkMutation.mutate(personid);

  /*
  const postsData = useQueries(
    subs.map((sub) => {
      return {
        queryKey: ["sub", sub],
        queryFn: () =>
          fetch("/api/people/" + sub + "/posts").then((res) => res.json()),
        enabled: !!subs,
      };
    })
  );

  const posts = postsData
    .map((query) => (query.isSuccess ? query.data : null))
    .flat()
    .filter((item) => item);
    

  const addLikeMutation = useMutation(
    (data: PostType) => {
      return axios.post(Urls.api.addLike(), data);
    },
    { onError: (e) => console.log(e) }
  );

  const addLike = (post: PostType) => {
    let data: PostType = {
      cursor: post.cursor,
      media: post.media,
      id: post.id ? post.id : "",
      personId: post.personId,
      personName: post.personName,
      time: post.time,
      url: post.url,
      slug: slug,
      likes: post.likes,
      aliases: post.aliases ? post.aliases : [],
    };
    addLikeMutation.mutate(data);
  };
  */

  return (
    <Wrapper>
      <Side>
        <PeopleList people={subs} removeSub={downfork} />
        <PeopleSearch subs={subs} addSub={upfork} />
        <Links slug={slug || ""} />
      </Side>
      <Main>
        <Headline headline={headliner || ""} setHeadline={setHeadline} />
        <ButtonWrapper>
          <EmojiButton
            value={"New"}
            emoji={"🕒"}
            onClick={() => {
              setFilterMode(FilterMode.NEW);
            }}
            selected={filterMode === FilterMode.NEW}
          />
          <EmojiButton
            value={"Popular"}
            emoji={"🔥"}
            onClick={() => {
              setFilterMode(FilterMode.POPULAR);
            }}
            selected={filterMode === FilterMode.POPULAR}
          />
        </ButtonWrapper>
        {filterMode === FilterMode.NEW ? (
          <ChamberInfiniteFeed slug={slug} subs={subs} />
        ) : (
          <InfiniteFeed filterMode={FilterMode.POPULAR} slug={slug} />
        )}
      </Main>
      <Chatbox>
        <Chat headline={headliner || ""} slug={slug || ""} />
      </Chatbox>
    </Wrapper>
  );
};

export default Chamber;
export const ButtonWrapper = styled.div`
  padding-left: 2em;
  display: flex;
  height: 64px;
  > * + * {
    margin-left: 8px;
  }
  @media (max-width: ${mobileWidth}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
    padding: 8px;
  }
`;

const Side = styled.div`
  width: 30%;
  box-sizing: border-box;

  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
  h3 {
    font-family: Arimo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
`;

const Main = styled.div`
  width: 100%;
  max-width: 40vw;
  @media (max-width: ${mobileWidth}) {
    max-width: 100vw;
  }
`;

const Chatbox = styled.div`
  width: 30%;
  border: 1px solid #44514f;
  box-sizing: border-box;
  border-radius: 6px;
  height: fit-content;
  max-height: 80vh;
  height: 80vh;
  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;

const ChamberHeadline = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 1em;
  box-sizing: border-box;
  h2 {
    padding-left: 0;
  }
  @media (max-width: ${mobileWidth}) {
    margin-top: 1em;
    margin-left: 4px;
    height: 120px;
  }
`;

const ChamberHeadlineButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.white};
  font-size: small;
  font-weight: bold;
  line-height: normal;
  height: fit-content;
  margin-left: 1em;
  :hover {
    text-decoration: underline;
    color: ${colors.contrastColor};
  }
`;

const HeadlineText = styled.h2`
  cursor: pointer;
`;
