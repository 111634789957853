import { Link } from "react-router-dom";
import { colors } from "../../utils/colors";
export const Logo = () => (
  <Link
    onClick={() => window.scrollTo(0, 0)}
    to="/"
    style={{
      fontWeight: "bold",
      color: colors.white,
      fontSize: "29px",
      lineHeight: "33px",
      textDecoration: "none",
      cursor: "pointer",
    }}
  >
    ECHO
  </Link>
);

export default Logo;
