import axios from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import styled from "styled-components";
import "./Chamber.css";
import InfiniteFeed from "./InifiniteFeed";
import { PeopleSearch } from "./PeopleSearch";
import { Urls } from "./Urls";
import EmojiButton from "../util/EmojiButton";
import { useState } from "react";
import Links from "./Links";
import { mobileWidth } from "../../utils/styles";

export const enum FilterMode {
  POPULAR,
  NEW,
}

export const FrontPage = () => {
  const navigate = useNavigate();

  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.POPULAR);

  const getSingletonForMutation = useMutation(
    (personId: string) =>
      axios.post(Urls.api.singleton(), { personid: personId }),
    {
      onSuccess: ({ data }) => {
        const url = Urls.www.getChamber(data.slug);
        navigate(url);
      },
    }
  );

  const getSingletonFor = (personId: string) => {
    getSingletonForMutation.mutate(personId);
  };

  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <Headline1>The Front Page of Social Media</Headline1>
          <PeopleSearch subs={[]} addSub={getSingletonFor} />
          <Links slug="homepage" />
        </LeftContainer>
        <RightContainer>
          <Headline2>
            {filterMode === FilterMode.POPULAR
              ? "Popular posts via ECHO"
              : "New posts via ECHO"}
          </Headline2>
          <ButtonWrapper>
            <EmojiButton
              value={"Popular"}
              emoji={"🔥"}
              onClick={() => {
                setFilterMode(FilterMode.POPULAR);
              }}
              selected={filterMode === FilterMode.POPULAR}
            />
            <EmojiButton
              value={"New"}
              emoji={"🕒"}
              onClick={() => {
                setFilterMode(FilterMode.NEW);
              }}
              selected={filterMode === FilterMode.NEW}
            />
          </ButtonWrapper>
          <InfiniteFeed filterMode={filterMode} />
        </RightContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  width: 40%;
  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  padding-left: 2em;
  width: auto;
  min-width: 600px;
  @media (max-width: ${mobileWidth}) {
    min-width: 0;
    width: 100%;
    padding-left: 0;
  }
`;

const Headline1 = styled.h1`
  @media (max-width: ${mobileWidth}) {
    font-size: 1.4em;
  }
`;
const Headline2 = styled.h2`
  line-height: 42px;
  @media (max-width: ${mobileWidth}) {
    font-size: 1.2em;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 64px;
  > * + * {
    margin-left: 8px;
  }
`;
